import { authReducer } from "./auth/reducer";
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import layoutReducer from "./layout/reducer";
import navbarReducer from "./navbar/reducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// -------------------

const rootPersistConfig = {
  key: "root",
  storage,
  blacklist: [
    "router",
    "userManegment",
    "products",
    "brands",
    "offers",
    "outlets",
    "outletType",
    "channels",
    "companyInfos",
    "prizes",
    "cities",
    "refusalReasons",
    "nationalities",
    "ageRanges",
    "promoters",
    "supervisors",
    "reports",
    "lookups",
    "shiftSessions",
    "dashboard",
    "survey",
    "group",
    "spinWheel",
  ],
};

export default function createReducer(asyncReducers, history) {
  return persistReducer(
    rootPersistConfig,
    combineReducers({
      ...asyncReducers,
      router: connectRouter(history),
      app: require("./app/reducer").reducer,
      auth: authReducer,
      layout: layoutReducer,
      navbar: navbarReducer,
      userManegment: require("./userManagement/reducer").reducer,
      settings: require("./setting/reducer").reducer,
      brands: require("./brands/reducer").reducer,
      products: require("./products/reducer").reducer,
      lookups: require("./lookups/reducer").reducer,
      companyInfos: require("./companyInfo/reducer").reducer,
      prizes: require("./prize/reducer").reducer,
      offers: require("./offers/reducer").reducer,
      outlets: require("./outlets/reducer").reducer,
      cities: require("./city/reducer").reducer,
      nationalities: require("./nationalities/reducer").reducer,
      ageRanges: require("./ageRange/reducer").reducer,
      promoters: require("./promoter/reducer").reducer,
      survey: require("./survey/reducer").reducer,
      supervisors: require("./supervisor/reducer").reducer,
      reports: require("./reports/reducer").reducer,
      dashboard: require("./dashboard/reducer").reducer,
      refusalReasons: require("./refusalReason/reducer").reducer,
      shiftSessions: require("./shiftSessions/reducer").reducer,
      outletType: require("./outletType/reducer").reducer,
      channels: require("./channel/reducer").reducer,
      group: require("./group/reducer").reducer,
      spinWheel: require("./spinningWheel/reducer").reducer,
    })
  );
}
